import { useEffect, useState } from "react";
import { loggedUserRole } from "utils/functions";
import BrokerageAnalytics from "./BrokerageAnalytics/Index";
import BrokerAnalytics from "./BrokerAnalytics/Index";
import VesselsAnalytics from "./VesselsAnalytics/Index";
const TabItem = ({ start_date, end_date }) => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    setRole(loggedUserRole());
//      // eslint-disable-next-line no-console
//     console.log(loggedUserRole());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserRole()]);

  return (
    <div className="max-h-8/10">
      <div className="flex flex-wrap mt-6 -mx-3 ">
        {(role === "Admin") && <BrokerageAnalytics startsAtDate={start_date} endsAtDate={end_date} />}
        {(role === "Admin") && <BrokerAnalytics startsAtDate={start_date} endsAtDate={end_date} />}
        {(role != "Guest") && <VesselsAnalytics startsAtDate={start_date} endsAtDate={end_date} />}
      </div>
    </div>
  );
};

export default TabItem;
