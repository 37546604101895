import {
  BookmarkIcon,
  BuildingOffice2Icon,
  ChartBarSquareIcon,
  DocumentChartBarIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  HomeIcon,
  ListBulletIcon,
  PlusIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  TagIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const sidebarList = [
  {
    name: "Dashboard",
    href: "/",
    icon: HomeIcon,
    current: true,
    accessTo: ["Admin", "Brokerage", "Broker"],
  },
  {
    name: "Brokers",
    icon: UsersIcon,
    current: false,
    accessTo: ["Brokerage"],
    children: [
      {
        name: "List All",
        href: "/broker",
        icon: ListBulletIcon,
        current: false,
      },
      {
        name: "Create Broker",
        href: "/broker/create",
        icon: PlusIcon,
        current: false,
      },
    ],
  },
  {
    name: "Brokerage",
    icon: BuildingOffice2Icon,
    current: false,
    accessTo: ["Admin"],
    children: [
      {
        name: "List All",
        href: "/brokerage",
        icon: ListBulletIcon,
        current: false,
      },
      {
        name: "Create Brokerage",
        href: "/brokerage/create",
        icon: PlusIcon,
        current: false,
      },
    ],
  },
  {
    name: "Reports",
    icon: DocumentChartBarIcon,
    current: false,
    accessTo: ["Brokerage", "Broker"],
    children: [
      {
        name: "Visitors",
        href: "/reports/visitors",
        icon: ListBulletIcon,
        current: false,
      },
    ],
  },
  {
    name: "Activity Log",
    href: "/activity",
    icon: DocumentChartBarIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "Followed Yachts",
    href: "/followed-yachts",
    icon: BookmarkIcon,
    current: false,
    accessTo: ["Guest"],
  },
  {
    name: "Saved Search Notifications",
    href: "/saved-search",
    icon: DocumentMagnifyingGlassIcon,
    current: false,
    accessTo: ["Guest"],
  },
  {
    name: "Boat Inquiry",
    href: "/boat-inquiry",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Brokerage", "Broker"],
  },
  {
    name: "Categories Analytics",
    href: "/categories-analytics",
    icon: PresentationChartBarIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "Brands Analytics",
    href: "/brands-analytics",
    icon: PresentationChartLineIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "Search History",
    href: "/search-history",
    icon: DocumentMagnifyingGlassIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "My Inquiries",
    href: "/my-inquiries",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Guest"],
  },
  {
    name: "Vessels Analytics",
    href: "/vessels-analytics",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "Visitors Analytics",
    href: "/visitors-analytics",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "Guest  Analytics",
    href: "/guest-analytics",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "Brokerage Analytics",
    href: "/brokerage-analytics",
    icon: ChartBarSquareIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "Vessel Inquiries",
    href: "/vessel-inquiries",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Admin"],
  },
  {
    name: "My Profile",
    href: "/my-profile",
    icon: UserIcon,
    current: true,
    accessTo: ["Admin", "Brokerage", "Broker", "Guest"],
  },
  {
    name: "Roles",
    href: "/roles",
    icon: TagIcon,
    current: false,
    accessTo: ["Admin"],
  },
  // {
  //   name: "Google Analytics",
  //   href: "/google-analytics",
  //   icon: GlobeAmericasIcon,
  //   current: false,
  //   accessTo: ["Admin"],
  // },

  // { name: "setting", href: "/setting", icon: HomeIcon, current: false },
];
