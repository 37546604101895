import { serverApi } from "store/serverApi";


const vesselsAnalyticsGraph = serverApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    brokerageAnalyticsGraph: builder.query({
      query(filter) {
        let start_date = filter?.start_date;
        let end_date = filter?.end_date;

        return {
          url: `admin-brokerage-vessel-analytics?start_date=${start_date || ""
            }&end_date=${end_date || ""}${filter?.hide_googlebot ? "&hide_googlebot=" + filter?.hide_googlebot : ""}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
    brokerAnalyticsGraph: builder.query({
      query(val) {
        // // eslint-disable-next-line no-console
        // console.log(val);

        let start_date = val?.filter?.start_date || '';
        let end_date = val?.filter?.end_date || '';
        let hide_googlebot = val?.filter?.hide_googlebot || '';
        return {
          url: `admin-brokeragewise-vessel-analytics/${val?.name}?start_date=${start_date}&end_date=${end_date}&hide_googlebot=${hide_googlebot}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
    vesselsAnalyticsGraph: builder.query({
      query(val) {
        let start_date = val?.filter?.start_date;
        let end_date = val?.filter?.end_date;

        return {
          url: `admin-brokerwise-vessel-analytics/${val?.name}?start_date=${start_date || ""
            }&end_date=${end_date || ""}&hide_googlebot=${val?.filter.hide_googlebot || ""
            }`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
    allBrokerAnalyticsGraph: builder.query({
      query(filter) {
        let start_date = filter?.start_date;
        let end_date = filter?.end_date;

        return {
          url: `admin-broker-vessel-analytics?start_date=${start_date || ""
            }&end_date=${end_date || ""} `,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useBrokerageAnalyticsGraphQuery,
  useBrokerAnalyticsGraphQuery,
  useVesselsAnalyticsGraphQuery,
  useAllBrokerAnalyticsGraphQuery,
} = vesselsAnalyticsGraph;
